<template>
  <Modal type="delete" :close="onClose" width="500px">
    <div slot="mBody" class="m-body">
      <div v-html="$t('delete_confirmation', {actionPlanName})"/>
    </div>
    <div slot="mFooter" class="m-footer btn-actions">
      <Button type="cancel" :text="$t('cancel')" @click="onClose"/>
      <Button type="primary" :text="$t('yes_delete')" @click="remove" :isLoading="btnLoading"/>
    </div>
  </Modal>
</template>

<script>
import ActionPlanService from "@/services/action.plan.service";

const service = new ActionPlanService();

export default {
  name: "DeleteActionPlan",

  props: {
    actionPlan: Object,
    onClose: Function,
    onDelete: Function,
  },

  data() {
    return {
      btnLoading: false
    }
  },

  computed: {
    actionPlanName() {
      return this.actionPlan.Name;
    }
  },

  methods: {
    async remove() {
      this.btnLoading = true;

      const res = await service.delete(this.actionPlan._id);

      this.btnLoading = true;

      if (res && !res.error) {
        await this.onRemove();
        this.$notify({type: 'success', message: this.$t('delete_success')});

      } else {
        this.$notify({type: 'error', message: this.$t('delete_error')});
      }
    },

    async onRemove() {
      if (
          this.$route.name === 'ActionPlansList'
      ) {
        this.onDelete({actionPlanId: this.actionPlan._id});
      }

      if (
          this.$route.name === 'ActionPlansDetails'
      ) {
        this.onClose();
        await this.$router.push({name: 'ActionPlansList'});
      }
    },
  },
}
</script>

<style scoped lang="scss">

.m-body {

  div {
    font-weight: $font-regular !important;
  }
}
</style>

<i18n>
{
  "en": {
    "cancel": "Cancel",
    "yes_delete": "Yes, Delete Action Plan",
    "delete_confirmation": "Are you sure you want to delete <span style='font-weight: bold'>{actionPlanName}</span>?",
    "delete_error": "Error deleting action plan",
    "delete_success": "Action plan successfully deleted"
  },
  "es": {
    "cancel": "Cancelar",
    "yes_delete": "Sí, eliminar plan de acción",
    "delete_confirmation": "¿Estás seguro de que quieres eliminar <span style='font-weight: bold'>{actionPlanName}</span>?",
    "delete_error": "Error al eliminar el plan de acción",
    "delete_success": "Plan de acción eliminado con éxito"
  }
}
</i18n>