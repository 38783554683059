import IService from './iservice';
import config from "@/config";
import store from "../store/store";

const server_base = config[config.stage].program_server;

class ActionPlanService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async create(actionPlan) {
    try {
      const response = await this.http.post(`${server_base}/api/action-plan`, actionPlan);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async get(id) {
    try {
      const response = await this.http.get(`${server_base}/api/action-plan/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async delete(id) {
    try {
      const response = await this.http.delete(`${server_base}/api/action-plan/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list(limit, skip, filters = {}) {
    try {
      const query = {...filters};

      if (limit) query.limit = limit;
      if (skip) query.skip = skip;

      this.addLoading('list_action_plans');
      const response = await this.http.get(`${server_base}/api/action-plan`, query);
      this.delLoading('list_action_plans');
      return response.data;
    } catch (e) {
      this.delLoading('list_action_plans');
      return null;
    }
  }

  async edit(actionPlan) {
    try {
      const response = await this.http.put(`${server_base}/api/action-plan`, actionPlan);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default ActionPlanService;